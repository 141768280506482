import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createFilter, components } from 'react-select'
import PropTypes from 'prop-types'
import classNames from './styles.module.scss'


SearchIcon.propTypes = {
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.shape({
    inputValue: PropTypes.string.isRequired,
    menuIsOpen: PropTypes.bool.isRequired,
  }).isRequired,
  selectOption: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
}

export default function SearchIcon(props) {
  const handleIconClick = () => {
    const { selectProps: { inputValue, menuIsOpen }, selectOption, options } = props
    if(inputValue && menuIsOpen) {
      const isMatch = createFilter()
      const optionToSelect = options?.find(opt => isMatch(opt, inputValue))
      if(optionToSelect) {
        selectOption(optionToSelect)
      }
    }
  }
  return (
    <components.DropdownIndicator
      {...props}
      innerProps={{
        ...props.innerProps,
        onMouseDown: e => {
          e.stopPropagation()
          e.preventDefault()
        },
        onTouchEnd: e => {
          e.stopPropagation()
          e.preventDefault()
        },
      }}
    >
      <FontAwesomeIcon className={classNames.indicator} icon="search" onClick={handleIconClick} />
    </components.DropdownIndicator>
  )
}
