import { Fragment, useContext, useMemo } from 'react'
import { Container, Button } from 'common/widgets'
import { HeroImage, Masonry } from 'common/widgets/blocks'
import SelectInput from 'common/forms/inputs/SelectInput'
import DropdownIndicator from './DropdownIndicator'
import cx from 'classnames'
import classNames from './styles.module.scss'
import { LanguageContext } from 'common/language'
import PropTypes from 'prop-types'


BlogLandingPageView.propTypes = {
  blogCategories: PropTypes.array.isRequired,
  searchOptions: PropTypes.array.isRequired,
  offset: PropTypes.number.isRequired,
  searchValue: PropTypes.string,
  heroImg: PropTypes.object.isRequired,
  heroType: PropTypes.oneOf(['three_line_hero_image']).isRequired,
  selectedTag: PropTypes.string.isRequired,
  masonryData: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({
      image: PropTypes.object,
      tag: PropTypes.string,
      date: PropTypes.string,
      heading: PropTypes.string,
      text: PropTypes.string,
      cta_url: PropTypes.string,
    })),
    cta_link_caption: PropTypes.string,
  }).isRequired,
  totalCount: PropTypes.number.isRequired,
  addMoreBlogPosts: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  selectCategoryFilter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

BlogLandingPageView.defaultProps = {
  searchValue: null,
  isLoading: false,
}

export default function BlogLandingPageView({ blogCategories, searchOptions, offset, searchValue, heroImg, heroType, selectedTag, masonryData, totalCount, addMoreBlogPosts, handleSearch, selectCategoryFilter, isLoading }) {
  const { gettext } = useContext(LanguageContext)
  const categories = useMemo(() => {
    const _categories = [{ name: gettext('All Categories'), uuid: null, slug: null }, ...blogCategories]
    return _categories.map(cat => (
      <li key={cat.name} className={classNames.tag}>
        <button
          className={cx(classNames.tagBtn, cat.name === selectedTag && classNames.selected)}
          onClick={() => selectCategoryFilter(cat)}
        >
          {cat.name}
        </button>
      </li>
    ))
  }, [blogCategories, selectedTag])

  return (
    <Fragment>
      <HeroImage data={heroImg} type={heroType} />
      <Container size="xl">
        <div className={classNames.flex}>
          <ul className={classNames.tags}>
            {categories}
          </ul>
          <SelectInput
            className={classNames.search}
            name="search"
            onChange={handleSearch}
            placeholder={gettext('Browse Blog')}
            components={{ DropdownIndicator: DropdownIndicator }}
            options={searchOptions}
            noOptionsMessage={() => gettext('No Results')}
            isSearchable isClearable isSmallFont
            value={searchValue}
          />
        </div>
      </Container>
      <Masonry headingTag="p" data={masonryData} />
      {offset + 3 < totalCount && (
        <div className={classNames.showMore}>
          <Button
            onClick={addMoreBlogPosts}
            className={classNames.button}
            isLoading={isLoading}
          >
            {gettext('more')}
          </Button>
        </div>)}
    </Fragment>
  )
}
