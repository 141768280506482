import { Fragment, useState, useContext } from 'react'
import Head from 'next/head'
import PropTypes from 'prop-types'
import { ResourceType } from '@ds-frontend/resource'
import API from 'api'
import BlogLandingPageView from './BlogLandingPageView'
import get from 'lodash/get'
import getImgFromBynder from 'common/utils/getImgFromBynder'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import { LanguageContext } from 'common/language'
import emptyPlaceholder from 'img/empty-placeholder.svg'


BlogLandingPageContainer.propTypes = {
  blogFilter: ResourceType.isRequired,
  blogLandingPage: PropTypes.object.isRequired,
  blogCategories: PropTypes.array.isRequired,
  searchOptions: PropTypes.array.isRequired,
  router: PropTypes.object.isRequired,
}

export default function BlogLandingPageContainer({ blogFilter, blogLandingPage, blogCategories, searchOptions, router }) {
  const [searchValue, setSearchValue] = useState(null)
  const { gettext } = useContext(LanguageContext)
  const defaultParams = { type: 'home.BlogPostPage', fields: 'blog_category,teaser_heading,teaser_text,teaser_image,creation_date', order: '-creation_date', limit: 3 }
  const addMoreBlogPosts = () => {
    const offset = (blogFilter?.filters?.offset || 0) + 3
    blogFilter.setLoading(true)
    API.get('api/v1/pages', { params: { ...defaultParams, ...blogFilter?.filters, offset } })
      .then(res => {
        blogFilter.setData({ meta: res.meta, items: blogFilter.data.items.concat(res.items) })
        blogFilter.setFilters({ ...blogFilter.filters, offset })
      })
      .finally(blogFilter.setLoading(false))
  }
  const clearSearchValue = () => setSearchValue(null)
  const setCategoryQueryParams = (slug) => {
    const url = new URL(window.location)
    slug ? url.searchParams.set('category', slug) : url.searchParams.delete('category')
    window.history.pushState({}, '', url)
    clearSearchValue()
  }
  const selectCategoryFilter = (category) => {
    blogFilter.fetch({ ...defaultParams, category_id: category.uuid })
    setCategoryQueryParams(category.slug)
  }
  const handleSearch = (value) => {
    if(value) {
      blogFilter.fetch({ ...defaultParams, search: value })
      setCategoryQueryParams()
    } else if(searchValue !== null) {
      blogFilter.fetch({ ...defaultParams })
    }
    setSearchValue(value)
  }
  const firstImageData = blogLandingPage?.content?.find(({ type }) => type.search('hero_') > -1)
  const ogImage = getImgFromBynder(get(firstImageData, 'value.image.bynder', {}), ['webImage'])
  const origin = typeof window === 'undefined' ? process.env.ORIGIN_URL : window.location.origin
  const robots = Object.entries(pick(blogLandingPage.meta, ['nofollow', 'noindex'])).reduce((arr, [key, value]) => value ? [...arr, key] : arr, [])
  const canonicalDe = origin + (get(blogLandingPage, 'meta.hreflang_data.de') || `/de${router.asPath}`)
  const canonicalFr = origin + (get(blogLandingPage, 'meta.hreflang_data.fr') || `/fr${router.asPath}`)

  const { type: heroType = 'three_line_hero_image', value: heroImg = emptyPlaceholder } = blogLandingPage?.content?.find(block => block.type === 'three_line_hero_image') || {}
  const masonryData = { columns: blogFilter.data.items.map(item => ({ tag: item.blog_category.name.toUpperCase(), date: item.creation_date, heading: item.teaser_heading, text: item.teaser_text, cta_link: '/' + item.slug, image: item?.teaser_image[0]?.value?.image })), cta_link_caption: gettext('Read Blog article') }
  const totalCount = blogFilter.data.meta.total_count
  const selectedTag = blogCategories.find(cat => cat.uuid === blogFilter?.filters?.category_id)?.name ?? gettext('All Categories')

  return (
    <Fragment>
      <Head>
        <title>{get(blogLandingPage, 'meta.seo_title', '')}</title>
        <meta name="description" content={get(blogLandingPage, 'meta.search_description', '')} key="description" />
        <meta name="keywords" content={get(blogLandingPage, 'seo_keywords', '')} />
        <meta property="og:title" content={get(blogLandingPage, 'title', '')} key="og-title" />
        <meta property="og:description" content={get(blogLandingPage, 'meta.search_description', '')} key="og-description" />
        <meta property="og:image" content={ogImage} key="og-image" />
        <meta property="og:url" content={origin + get(blogLandingPage, 'meta.canonical_url', '')} key="og-url" />
        <meta property="og:type" content="website" key="og-type" />
        <link rel="canonical" href={origin + get(blogLandingPage, 'meta.canonical_url', '')} />
        <link rel="alternate" hrefLang="de" href={canonicalDe} />
        <link rel="alternate" hrefLang="fr" href={canonicalFr} />
        <link rel="alternate" hrefLang="x-default" href={origin + get(blogLandingPage, 'meta.hreflang_data.default', '')} />
        {!isEmpty(robots) && (
          <meta name="robots" content={robots.join(',')} />
        )}
      </Head>
      <BlogLandingPageView
        offset={blogFilter?.filters?.offset || 0}
        searchValue={searchValue}
        blogCategories={blogCategories}
        searchOptions={searchOptions}
        heroType={heroType}
        heroImg={heroImg}
        masonryData={masonryData}
        totalCount={totalCount}
        selectedTag={selectedTag}
        addMoreBlogPosts={addMoreBlogPosts}
        handleSearch={handleSearch}
        selectCategoryFilter={selectCategoryFilter}
        isLoading={blogFilter.isLoading}
      />
    </Fragment>
  )
}
