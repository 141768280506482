import { compose } from 'redux'
import { connectResources } from '@ds-frontend/resource'
import { withRouter } from 'next/router'
import BlogLandingPageContainer from './BlogLandingPageContainer'


export default compose(
  withRouter,
  connectResources({
    namespace: 'blogFilter',
    endpoint: 'api/v1/pages',
    queries: ['category_id', 'type', 'fields', 'search', 'order', 'offset', 'limit'],
  })
)(BlogLandingPageContainer)
