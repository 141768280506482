import API from 'api'
import globalBlogRequest from 'common/utils/globalBlogRequest'
import getCampaignBanner from 'common/utils/getCampaignBanner'
import callPromiseWithRetry from 'common/utils/callPromisewithRetry'
import AppLayout from 'layouts/AppLayout'
import BlogLandingPage from 'components/blog/BlogLandingPage'
import get from 'lodash/get'
import set from 'lodash/set'
import PropTypes from 'prop-types'


BlogLP.propTypes = {
  blogLandingPage: PropTypes.object.isRequired,
}

export async function getServerSideProps({ locale, query }) {
  const requiredLocale = process.env.EXCLUDE_LOCALES.includes(locale) ? 'de' : locale
  const blogInfoTranslations = await globalBlogRequest(requiredLocale)
  const blogCategories = get(blogInfoTranslations, 'blogInfo.data.categories', [])
  const searchOptions = get(blogInfoTranslations, 'blogInfo.data.search_by_options', []).map(option => ({ value: option, label: option })).sort((a, b) => a.value.toUpperCase() < b.value.toUpperCase()
    ? -1
    : a.value.toUpperCase() === b.value.toUpperCase()
      ? 0
      : 1)
  const selectedCategory = blogCategories.find(category => category.slug === query?.category)?.uuid
  const callBlogPagesData = () => API.get('api/v1/pages', { headers: { 'Accept-Language': requiredLocale }, params: { 'type': 'home.BlogPostPage', 'fields': 'blog_category,teaser_heading,teaser_text,teaser_image,creation_date', 'category_id': selectedCategory, 'order': '-creation_date', offset: 0, limit: 3 } })
  const callBlogLPData = () => API.get('api/v1/pages/blog', { headers: { 'Accept-Language': requiredLocale } })
  const [blogPosts, blogLandingPage] = await Promise.all([callPromiseWithRetry(callBlogPagesData), callPromiseWithRetry(callBlogLPData)])
    .catch(err => err)
  const initialReduxStore = { blogFilter: { data: blogPosts, filters: { offset: 0, limit: 3 } }, ...blogInfoTranslations }
  if(selectedCategory) {
    set(initialReduxStore, 'blogFilter.filters.category_id', selectedCategory)
  }
  const campaignBanner = getCampaignBanner({ slug: 'blog', homeSlug: get(initialReduxStore, 'cmsInfo.data.home_page_slugs.' + requiredLocale), banners: get(initialReduxStore, 'cmsInfo.data.campaign_banners') })
  return { props: { initialReduxStore: { ...initialReduxStore, ...campaignBanner }, blogLandingPage, blogCategories, searchOptions } }
}

export default function BlogLP(props) {
  const isHeaderFooterEnabled = {
    isHeaderEnabled: get(props, 'blogLandingPage.display_header', true),
    isFooterEnabled: get(props, 'blogLandingPage.display_footer', true) }
  return (
    <AppLayout withCover {...isHeaderFooterEnabled}>
      <BlogLandingPage {...props} />
    </AppLayout>
  )
}
